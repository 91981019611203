import { LupaSortDirection, SortDirection } from '@lib/types/lupa'

export const PHRASE_AUTOCOMPLETE_KEY =
  process.env.NEXT_PUBLIC_LUPA_PHRASE_AUTOCOMPLETE_KEY
export const PRODUCT_AUTOCOMPLETE_KEY =
  process.env.NEXT_PUBLIC_LUPA_PRODUCT_AUTOCOMPLETE_KEY
export const LUPA_DOCUMENTS_KEY = process.env.NEXT_PUBLIC_LUPA_DOCUMENTS_KEY
export const LUPA_SEARCH_KEY = process.env.NEXT_PUBLIC_LUPA_SEARCH_KEY
export const LUPA_BRAND_LIST_KEY = process.env.NEXT_PUBLIC_LUPA_BRANDS_KEY
export const LUPA_API_URL = 'https://api.lupasearch.com/v1/query/'
export const LUPA_EVENTS_URL = 'https://api.lupasearch.com/v1/events'
export const LUPA_SEARCH_DOCUMENT_LIMIT = 5
export const LUPA_SEARCH_SUGGESTION_LIMIT = 4

export const lupaDefaultParameters = {
  method: 'POST',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

export const DEFAULT_PAGE_LIMIT = 30
export const SKELETON_PRODUCTS_COUNT = 6

export const SEARCH_ID_COOKIE_NAME = 'searchId'

export const SortDetails: Record<string, Record<string, LupaSortDirection>[]> =
  {
    [SortDirection.PriceHighToLow]: [{ price: LupaSortDirection.DESC }],
    [SortDirection.PriceLowToHigh]: [{ price: LupaSortDirection.ASC }],
    [SortDirection.Discount]: [{ discount: LupaSortDirection.DESC }],
    [SortDirection.NewArrivals]: [{ createdAt: LupaSortDirection.DESC }],
  }

export const LUPA_DISCOUNT_FACET_KEY = 'onDiscount'
export const LUPA_NEW_FACET_KEY = 'isNew'
